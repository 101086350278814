<template>
    <v-card>
        <v-card-title class="pb-0 pt-1" >
            <v-icon style="background: #e7e8e9; padding: 10px!important; border-radius: 50%;" class="mr-4">mdi-file-document-outline</v-icon>
            <span >Editar {{quole}}</span>
            <v-spacer></v-spacer>
            <!--v-col cols="12" sm="6" md="2" class="pb-0 mb-0">
                <v-radio-group v-model="type" class="my-0">
                    <v-radio label="Agencia" color="primary" value="agency"></v-radio>
                    <v-radio label="Marca" color="primary" value="brand"></v-radio>
                </v-radio-group>
            </v-col-->
            <v-col cols="12" sm="6" md="3">
                <v-autocomplete  :rules="[v => !!v || 'Campo requerido']" v-if="permissions('assignSales')" clearable v-model="quotation.user_id" :items="usersLists" label="Responsable" item-text="full_name" item-value="id">
                    <template slot="no-data">No existen usuarios relacionados.</template>                      
                </v-autocomplete>
                <v-autocomplete v-else disabled v-model="quotation.user_id" :items="usersLists" label="Responsable" item-text="full_name" item-value="id">                
                </v-autocomplete>
            </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 mb-0">
            <v-container>
                <v-row class="pt-5 pb-0">
                    <v-col class="pt-0" cols="12" sm="6" md="9">
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete v-model="quotation.agency_id" :items="agencyLists" :loading="isLoadingAgencies" :search-input.sync="searchAgencies" hide-no-data item-value="id" item-text="name" label="Agencia(s)" placeholder="Escribe para buscar">
                                    <template slot="no-data"><div class="px-4 py-1">No existen agencias relacionadas.</div></template>  
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete v-model="quotation.brand_id" :items="brandLists" :loading="isLoadingBrands" :search-input.sync="searchBrands" hide-no-data item-value="id" item-text="name" label="Marca(s)" placeholder="Escribe para buscar">
                                    <template slot="no-data"><div class="px-4 py-1">No existen marcas relacionadas.</div></template>  
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-col> 
                    <v-col class="pt-0" cols="12" sm="6" md="3">
                        <v-autocomplete clearable v-model="quotation.sale_type" :items="series" label="Tipo de Venta">
                            <template slot="no-data">No existen tipos de venta relacionados.</template>                      
                        </v-autocomplete>
                    </v-col>
                </v-row>  

                <v-row class="mx-0 mb-0" v-if="quotation.agency_id!=null">
                    <v-autocomplete class="mr-4" :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.finance_contact_id" :items="agency_contacts" item-value="id" item-text="name" label="Contacto de Finanzas" placeholder="Escribe para buscar">
                        <template v-slot:item="{item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title style="font-size:14px;">
                                        <v-row class="ma-0 mt-2" style="color:grey;">
                                            <div>
                                                {{item.name + ' ' + item.last}}
                                                <br/>
                                                 <span style="font-size:11px; font-weight:600; color:grey;">
                                                    {{item.email}} | {{item.phone}}
                                                </span>
                                            </div>
                                            <v-spacer/>
                                            <v-chip class="my-1" x-small>{{item.job_position}}</v-chip>
                                        </v-row>
                                       
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template> 
                    </v-autocomplete>
                    <v-autocomplete class="ml-4" :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.campaign_contact_id" :items="agency_contacts" item-value="id" item-text="name" label="Contacto de Campaña" placeholder="Escribe para buscar">
                        <template v-slot:item="{item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content>
                                    <v-list-item-title style="font-size:14px;">
                                        <v-row class="ma-0 mt-2" style="color:grey;">
                                            <div>
                                                {{item.name + ' ' + item.last}}
                                                <br/>
                                                 <span style="font-size:11px; font-weight:600; color:grey;">
                                                    {{item.email}} | {{item.phone}}
                                                </span>
                                            </div>
                                            <v-spacer/>
                                            <v-chip class="my-1" x-small>{{item.job_position}}</v-chip>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template> 
                    </v-autocomplete>
                </v-row>

                <v-row style="background-color: #94949417;" class="px-8 ma-0 pb-3 pt-0 mb-0">
                    <v-col cols="12" sm ="4" md="4" class="pl-0">
                        <v-autocomplete v-model="quotation.influencer_id" :items="influencerLists" :loading="isLoadingInfluencers" :search-input.sync="searchInfluencers" hide-no-data item-value="id" item-text="name" label="Influencer(s)" placeholder="Escribe para buscar">
                            <template slot="no-data"><div class="px-4 py-1">No existen influencers relacionadas.</div></template>  
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm ="8" md="4">
                        <v-text-field v-model="quotation.influencer_amount" prefix="$" :disabled="quotation.subtotal==0" label="Comisión"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm ="8" md="4">
                        <v-text-field v-model="quotation.campaign" label="Campaña"></v-text-field>
                    </v-col>

                    

                    <v-row class="mb-2">
                        <v-col ols="12" sm ="8" md="4" class="py-1 my-0">
                            <v-text-field v-model="quotation.service_date" label="Fecha de Servicio"></v-text-field>
                        </v-col>
                        <v-col ols="12" sm ="8" md="4" class="py-1 my-0">
                            <v-text-field v-model="quotation.service" :disabled="quotation.influencer_id==''" label="Servicio"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm ="8" md="4" class="py-1 my-0">
                            <v-text-field v-model="quotation.subtotal" :disabled="quotation.influencer_id==''" type="number" prefix="$" suffix="c/u" label="Precio (sin IVA)"></v-text-field>
                        </v-col>
                    </v-row>
                </v-row>
                <v-row class="mt-0 px-6">
                    <v-col cols="12" sm="6" md="6" class="mt-0 pt-0">
                        <v-container fluid>
                            <v-textarea v-model="quotation.description" label="Descripcion"></v-textarea>
                        </v-container>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-0 pt-0">
                        
                        <v-row class="ma-0">
                            <v-text-field v-model="quotation.invoice" label="Factura"></v-text-field>
                            
                            <v-menu top offset-x class="hidden-md-and-down">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn style="font-size:11px;" text v-bind="attrs" v-on="on" :color="fileColor(quotation.peach_invoice_file)" class="mt-2">
                                        <v-icon class="mr-2" small>mdi-attachment</v-icon> Factura Marca
                                    </v-btn>
                                </template>
                                <vue-dropzone ref="myVueDropzone3" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess3" v-on:vdropzone-error="uploadError3" v-on:vdropzone-removed-file="fileRemoved3"/>
                                <div class="pa-2" style="background:white;">
                                    <v-chip close @click:close="editedQuotation.peach_invoice_file=''" small :href="'https://unopack.mx/files/' + editedQuotation.peach_invoice_file" target="_blank">{{editedQuotation.peach_invoice_file}}</v-chip>
                                </div>
                            </v-menu>
                        </v-row>

                        <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" clearable required v-model="quotation.invoice_date" label="Fecha de Facturación" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotation.invoice_date" @input="datePicker = false"></v-date-picker>
                        </v-menu>
                        <!--v-menu v-model="datePicker2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.payment_promise_date" label="Fecha Promesa de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotation.payment_promise_date" @input="datePicker = false"></v-date-picker>
                        </v-menu-->
                        <v-menu v-model="datePicker2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.deadline_date" label="Fecha Cierre" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotation.deadline_date" @input="datePicker2 = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-row class="ma-0 pa-0 mt-0 pt-0">
            <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">
                <v-menu top offset-x class="hidden-md-and-down">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="font-size:12px;" text v-bind="attrs" v-on="on" :color="fileColor(pdfs)">
                            <v-icon class="mr-2">mdi-attachment</v-icon> Contrato
                        </v-btn>
                    </template>
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions2" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError" v-on:vdropzone-removed-file="fileRemoved"/>
                    <div class="pa-2" style="background:white;">
                        <v-chip close @click:close="removePdf(pdf)" small :href="'https://unopack.mx/files/' + pdf" target="_blank" v-for="(pdf, index) in quotation.pdfs" :key="index">{{pdf}}</v-chip>
                    </div>
                </v-menu>

                <v-menu top offset-x class="hidden-md-and-down"> 
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="font-size:12px;" text v-bind="attrs" v-on="on" :color="fileColor(quotation.invoice_file)">
                            <v-icon class="mr-2">mdi-attachment</v-icon> Factura Influencer
                        </v-btn>
                    </template>
                    <vue-dropzone ref="myVueDropzone2" id="dropzone2" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess2" v-on:vdropzone-error="uploadError2" v-on:vdropzone-removed-file="fileRemoved2"/>
                    <div class="pa-2" style="background:white;">
                        <v-chip close @click:close="editedQuotation.invoice=''" small :href="'https://unopack.mx/files/' + editedQuotation.invoice" target="_blank">{{editedQuotation.invoice}}</v-chip>
                    </div>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">
                <v-card-actions>
                    <v-spacer class="hidden-md-and-down"></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save" :disabled="gris || gris2" :loading="gris2">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show" style="text-align:center;">
            <strong>{{ snackbar.message }}</strong>
        </v-snackbar>
    </v-card>
</template>



<script>

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CreateContact from "../clients/contacts/create"
import CreateCompany from "../clients/companies/create"
import axios from "axios";

    export default {
        props:{
            editedQuotation:Object
        }, 
        created(){
            this.rellenar()
        },
        components: {
            vueDropzone: vue2Dropzone,
            'createContact':CreateContact,
            'createCompany':CreateCompany,
        },   
        data(){
            return {
                pdfs:[],
                fileName2:'',
                fileName:'',
                quotation:{},
                snackbar: {
                    show: false,
                    message: null,
                    color: null
                },
                gris2:false,
                pause:false,
                company:'',
                status:'',
                datePicker:'',
                datePicker2:false,
                dropzoneOptions: {
                    url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/file",
                    addRemoveLinks: true,
                    maxFiles: 1
                },
                dropzoneOptions2: {
                    url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/file",
                    addRemoveLinks: true,
                    maxFiles: 10
                },
                series:[{text:'Con Factura', value:'Serie A'}, {text:'Sin Factura', value:'Serie B'}],
                rules: {
                    required: value => !!value || 'Campo requerido',
                },
                valid: true,
                entriesAgencies:[],
                entriesBrands:[],
                entriesInfluencers:[],
                isLoadingAgencies: false,
                isLoadingBrands: false,
                isLoadingInfluencers: false,
                searchAgencies:'',
                searchBrands:'',
                searchInfluencers:'',
                agency_contacts:[],
            }
        },
        watch: {
            influencer:{
                handler: function (val, oldVal) {
                    if(((val*1)!=(oldVal*1)) && this.quotation.influencer_id!= this.editedQuotation.influencer_id){
                        var commission_percentage = this.entriesInfluencers.filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.commission_percentage)[0]*1
                        if(commission_percentage!==0){
                            this.quotation.influencer_amount = (((this.quotation.subtotal/1.16)/100)*commission_percentage).toFixed(2)
                        }
                    }
                },
                deep: true,
            },
            subtotal:{
                handler: function (val, oldVal) {
                    if(((val*1)!=(oldVal*1))  && this.quotation.subtotal!= this.editedQuotation.subtotal){
                        var commission_percentage = this.entriesInfluencers.concat([this.editedQuotation.influencer]).filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.commission_percentage)[0]*1
                        if(commission_percentage!==0){
                            this.quotation.influencer_amount = (((this.quotation.subtotal/1.16)/100)*commission_percentage).toFixed(2)
                        }
                    }
                    
                },
                deep: true,
            },
            editedQuotation:{
                handler(){
                    this.rellenar()
                    
                }, deep:true,
            },
            searchInfluencers(val){
                if(val!=null){
                    var link = ''
                    if(this.currentUser.role == 'Talent Agent' || this.currentUser.role == 'Booking'){
                        link = '&filter[parent_id]=' + this.currentUser.id
                    }
                    //if (this.influencerLists.length > 0) return
                    if (this.isLoadingInfluencers) return
                    this.isLoadingInfluencers = true
                    axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/influencer/search?filter[social_networks]='+val+link)
                    .then(res => {
                        if(this.entriesInfluencers.length>0){
                            this.entriesInfluencers = this.entriesInfluencers.concat(res.data.data)
                        }else{
                            this.entriesInfluencers = res.data.data
                        }
                    }).finally(() => (this.isLoadingInfluencers = false))
                }   
            },
            searchAgencies(val){
                if (this.isLoadingAgencies) return
                this.isLoadingAgencies = true
                //var filter = this.$store.state.currentUser.client_filter
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/agencies?'+'filter[name]='+val)//+filter
                .then(res => {
                    //this.provisionalEntriesAgencies = JSON.parse(JSON.stringify(res.data.data))
                    if(this.entriesAgencies.length>0){
                        this.entriesAgencies = this.entriesAgencies.concat(res.data.data)
                    }else{
                        this.entriesAgencies = res.data.data
                    }
                }).finally(() => (this.isLoadingAgencies = false))
            },
            searchBrands(val){
                if (this.isLoadingBrand) return
                this.isLoadingBrand = true
                var filter = ''
                if(this.quotation.agency_id!=null){
                    filter = 'filter[agency_id]=' + this.quotation.agency_id + '&'
                }
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/brands?' + filter + "filter[name]=" + val)
                .then(res => {
                    if(this.entriesBrands.length>0){
                        this.entriesBrands = this.entriesBrands.concat(res.data.data)
                    }else{
                        this.entriesBrands = res.data.data
                    }
                }).finally(() => (this.isLoadingBrand = false))
            },
            finance_contact:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        this.quotation.email = this.agency_contacts.filter(contact=>contact.id == this.finance_contact)[0].email
                    }
                },
                deep: true,
            },
            campaign_contact:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        this.whatsapp = this.agency_contacts.filter(contact=>contact.id == this.campaign_contact)[0].phone
                    }
                },
                deep: true,
            },
        },
        computed: {
            influencer(){
                return this.quotation.influencer_id*1
            },
            subtotal(){
                return this.quotation.subtotal
            },
            gris(){
                if(this.quotation.influencer_id==''||(this.quotation.brand_id==null&&this.quotation.agency_id==null)||this.quotation.deadline_date==''){
                    return true
                }else{
                    return false
                }
            },
            influencerLists(){
                var perro = []
                if(this.entriesInfluencers!=undefined){
                    perro = this.entriesInfluencers.map(id=>{
                        return{
                            id:id.id,
                            name: id.social_networks.instagram,
                            is_booking: id.is_booking
                        }
                    })
                }
                return perro.concat([this.editedQuotation.influencer].map(id=>{
                    return{
                        id:id.id,
                        name: id.social_networks.instagram,
                        is_booking: id.is_booking
                    }
                }))
            },
            agencyLists(){
                var perro = []
                this.pause = true
                if(this.entriesAgencies){
                    perro = this.entriesAgencies.map(id => {
                        return{
                            id:id.id,
                            name:id.name
                        }
                    })
                }
                return perro.concat(this.editedQuotation.agency).map(id => {
                    return{
                        id:id.id,
                        name:id.name
                    }
                })
            },
            brandLists(){
                var perro = []
                if(this.entriesBrands){
                    perro = this.entriesBrands.map(id => {
                        return{
                            id:id.id,
                            name:id.name
                        }
                    })
                }
                return perro.concat(this.editedQuotation.brand).map(id => {
                    return{
                        id:id.id,
                        name:id.name
                    }
                })
            },
            usersLists(){
                return this.$store.state.user.salesman.map(id=>{return{
                    ...id,
                    full_name: id.name + ' ' + id.last
                }});
            }, 
            currentUser:{
                get(){
                    return this.$store.state.currentUser.user
                }
            },
            quole(){
                if(this.status=='vendido'){
                    return 'Venta'
                }else if(this.status=='quotation'){
                    return 'Cotización'
                } 
            } 
        },
        methods: {
            rellenar(){
                console.log(this.editedQuotation)
                if(this.editedQuotation.influencer!=undefined){
                    this.entriesInfluencers = [this.editedQuotation.influencer]
                }
                if(this.editedQuotation.agency!=undefined){
                    this.entriesAgencies = [this.editedQuotation.agency]
                    this.agency_contacts = this.entriesAgencies.map(agency=>agency.contacts)[0]
                }
                if(this.editedQuotation.brand!=undefined){
                    this.entriesBrands = [this.editedQuotation.brand]
                }
                this.quotation={
                    brand_id:this.editedQuotation.brand_id*1,
                    agency_id:this.editedQuotation.agency_id*1,
                    user_id:this.editedQuotation.user_id,
                    pdfs:this.undefinedToJson(this.editedQuotation.pdfs),
                    description:this.editedQuotation.description,
                    sale_status:this.editedQuotation.sale_status,
                    sale_type:this.editedQuotation.sale_type,
                    invoice:this.editedQuotation.invoice,
                    created_by_user_id:this.editedQuotation.created_by_user_id,
                    last_updated_by_user_id:this.editedQuotation.last_updated_by_user_id,
                    influencer_id:this.editedQuotation.influencer_id,
                    service_date:this.editedQuotation.service_date,
                    service:this.editedQuotation.service,
                    subtotal:this.editedQuotation.subtotal*1,
                    influencer_amount:this.editedQuotation.influencer_amount*1,
                    campaign:this.editedQuotation.campaign,
                    invoice_date:this.editedQuotation.invoice_date,
                    payment_promise_date:this.editedQuotation.payment_promise_date,
                    invoice_file:this.editedQuotation.invoice_file,
                    finance_contact_id:this.editedQuotation.finance_contact_id,
                    campaign_contact_id:this.editedQuotation.campaign_contact_id,
                    peach_invoice_file:this.editedQuotation.peach_invoice_file
                }
            },
            undefinedToJson(item){
                if(item!=undefined){
                    return item
                }else{
                    return []
                }
            },
            fileColor(item){
                if(item!=''&&item!=undefined&&item!=null){
                    return 'success'
                }
            },
            permissions(permission){
                if(this.currentUser.id==1){
                    return true
                }else if(this.currentUser.permissions!=undefined){
                    if(this.currentUser.permissions.includes(permission)){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },



            uploadSuccess2(file, response) {
                const perro = response.file
                console.log('File Successfully Uploaded with file name: ' + perro);
                this.fileName2 = file;
                this.quotation.invoice_file = perro;
            },
            uploadError2(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved2() {
                
            },

            uploadSuccess3(file, response) {
                const perro = response.file
                console.log('File Successfully Uploaded with file name: ' + perro);
                this.fileName3 = file;
                this.quotation.peach_invoice_file = perro;
            },
            uploadError3(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved3() {
                this.quotation.peach_invoice_file = ''
            },

            uploadSuccess(file, response) {
                const perro = response.file
                console.log('File Successfully Uploaded with file name: ' + perro);
                this.$refs.myVueDropzone.removeFile(file)
                console.log(this.quotation)
                this.quotation.pdfs.push(perro);
            },
            uploadError(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved() {
                
            },

            removePdf(pdf_to_remove){
                const respuesta = confirm("¿Estás segura de que deseas eliminar el archivo " + pdf_to_remove + "?");
                if (respuesta) {
                    this.quotation.pdfs = this.quotation.pdfs.filter(pdf=>pdf!=pdf_to_remove)
                }
            },


            close () {
                if(this.fileName != ''){
                    this.$refs.myVueDropzone.removeFile(this.fileName)
                    this.fileName = ''
                }
                if(this.fileName2 != ''){
                    this.$refs.myVueDropzone2.removeFile(this.fileName2)
                    this.fileName2 = ''   
                }
                this.$nextTick(() => {
                    this.gris2 = false
                    this.$nextTick(() => {
                        this.$emit("closeDialogEditQuotation", false);
                    })
                })
            },
            save(){
                this.gris2 = true
                this.quotation.last_updated_by_user_id = this.currentUser.id
                this.quotation.total = this.quotation.subtotal*1.16
                axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/"+this.editedQuotation.id, this.quotation).then(response=>{
                    
                    this.close()
                    this.snackbar = {
                        message: 'Venta editada con éxito!',
                        color: 'successful',
                        show: true
                    }
                }).catch(error => {
                    this.snackbar = {
                        message:error,
                        color: 'error',
                        show: true
                    }
                })
            },
        },
}
</script>
<style lang="css" scoped>
.v-text-field >>> label{
    font-size: 14px!important;
}
</style>
<style>
.v-input__icon--prepend-inner i::before {
    font-size: 18px!important;
    color:#1976D2;
}
.v-text-field input, .v-label {
    font-size: 14px;
}
.v-picker__title.primary{
    display:none;
}
</style>