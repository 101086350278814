<template>
    <v-card>
        <v-card-title class="pb-0 pt-1" style="min-height:74px;">
            <v-icon style="background: #e7e8e9; padding: 10px!important; border-radius: 50%;" class="mr-4">mdi-file-document-outline</v-icon>
            <span >Nuevo Gasto</span>
            <v-spacer/>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
            <v-container>
                <v-row>

                    <v-col cols="12" sm="6" md="6" class="pb-0">
                        <v-card class="elevation-0 pa-3">
                            <v-card-subtitle class="ma-0 pa-0">Folio Venta: {{sale.id}}</v-card-subtitle>
                            <v-card-title style="font-size:16px;" class="ma-0 pa-0">
                                Agencia: {{sale.agency}}
                            </v-card-title>
                            <v-card-title style="font-size:16px;" class="ma-0 pa-0">
                                Marca: {{sale.brand}}
                            </v-card-title>
                            <v-card-title style="font-size:16px;" class="ma-0 pa-0">
                                Subtotal Venta: {{(sale.subtotal*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}} + IVA
                            </v-card-title>
                            <v-text-field v-model="expense.amount" placeholder="Monto de Gasto" prefix="$"></v-text-field>
                            <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                                <template v-slot:activator="{ on }">
                                    <v-text-field :rules="[v => !!v || 'Campo requerido']" required v-model="expense.payment_date" label="Fecha de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker color="primary" v-model="expense.payment_date" @input="datePicker = false"></v-date-picker>
                            </v-menu>
                        </v-card>
                    </v-col> 

                    <v-col cols="12" sm="6" md="6" class="pb-0">
                        <v-select v-model="expense.concept" :items="concepts" placeholder="Concepto"></v-select>

                        <v-autocomplete v-if="expense.concept == 'Prestamo Influencer' || expense.concept == 'Viaticos'" :rules="[v => !!v || 'Campo requerido']" required v-model="expense.influencer_id" :items="influencersList" :loading="isLoadingInfluencers" :search-input.sync="searchInfluencers" hide-no-data item-value="id" item-text="name" label="Influencer" placeholder="Escribe para buscar">
                            <template slot="no-data"><div class="px-4 py-1">No existen influencers relacionadas.</div></template>  
                        </v-autocomplete>
                        <v-autocomplete v-else-if="expense.concept == 'Producción'" :rules="[v => !!v || 'Campo requerido']" v-model="expense.user_id" :items="usersList" label="Usuario" item-text="name" item-value="id">
                            <template slot="no-data" class="pa-2">No existen usuarios relacionados.</template>                      
                        </v-autocomplete>

                        <v-textarea class="space" background-color="amber lighten-4" v-model="expense.note" label="Nota"></v-textarea>

                        
                    </v-col>
                    
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
            <v-spacer class="hidden-md-and-down"></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
                Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="gris || gris2" :loading="gris2">
                Guardar
            </v-btn>
        </v-card-actions>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show" style="text-align:center;">
            <strong>{{ snackbar.message }}</strong>
        </v-snackbar>
    </v-card>
</template>

<script>
import axios from "axios";
    export default {
        props:{sale:Object},
        data: () => ({
            snackbar: {
                show: false,
                message: null,
                color: null
            },
            gris2: false,
            datePicker:false,
            datePicker2:false,
            concepts:['Viaticos', 'Producción'], //'Prestamo Influencer'
            expense:{
                sale_id:'',
                percentage_over_sale:'',
                concept:'',
                influencer_id:'',
                user_id:'',
                payment_date:'',
                deadline_date:'',
                status:'activo'
            },
            rules: {
                required: value => !!value || 'Campo requerido',
            },
            valid: true,
            entriesInfluencers:[],
            isLoadingInfluencers: false,
            searchInfluencers:'',
            entriesSales:[],
            isLoadingSales: false,
            searchSales:''
        }),
        created(){
            this.expense.sale_id = this.sale.id
            this.expense.deadline_date = this.sale.deadline_date
            this.entriesInfluencers = [this.sale.influencer]
            this.expense.influencer_id = this.sale.influencer.id
        },
        watch: {
            sale:{
                handler(){
                    this.expense.sale_id = this.sale.id
                    this.expense.deadline_date = this.sale.deadline_date
                    this.entriesInfluencers = [this.sale.influencer]
                    this.expense.influencer_id = this.sale.influencer.id
                }, deep:true,
            },
            influencer:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        var commission_percentage = this.entriesInfluencers.filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.commission_percentage)[0]*1
                        this.sale.influencer_amount = (((this.sale.total/1.16)/100)*commission_percentage).toFixed(2)
                    }
                },
                deep: true,
            },
            total:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        var commission_percentage = this.entriesInfluencers.filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.commission_percentage)[0]*1
                        if(commission_percentage!==0){
                            this.sale.influencer_amount = (((this.sale.total/1.16)/100)*commission_percentage).toFixed(2)
                        }
                    }
                    
                },
                deep: true,
            },
            searchInfluencers(val){
                if(val!=null){
                    var link = ''
                    if(this.currentUser.role == 'Talent Agent' || this.currentUser.role == 'Booking'){
                        link = '&filter[parent_id]=' + this.currentUser.id
                    }
                    //if (this.influencerLists.length > 0) return
                    if (this.isLoadingInfluencers) return
                    this.isLoadingInfluencers = true
                    axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/influencer/search?filter[social_networks]='+val+link)
                    .then(res => {
                        if(this.entriesInfluencers.length>0){
                            this.entriesInfluencers = res.data.data
                        }else{
                            this.entriesInfluencers = res.data.data
                        }
                    }).finally(() => (this.isLoadingInfluencers = false))
                }   
            },
        },
        computed: {
            usersList(){
                return this.$store.state.user.users.filter(user=>user.role == 'Producción');
            }, 
            influencer(){
                return this.expense.influencer_id
            },
            gris(){

                if(this.vacio(this.expense.sale_id)
                ||(this.vacio(this.expense.percentage_over_sale)&&this.vacio(this.expense.amount))
                ||this.vacio(this.expense.concept)
                ||(this.vacio(this.expense.influencer_id)&&this.vacio(this.expense.user_id))
                ||this.vacio(this.expense.payment_date)||this.vacio(this.expense.deadline_date)){
                    return true
                }else{
                    return false
                }
            },
            salesList(){
                return this.entriesSales.map(id=>{
                    return{
                        id:id.id,
                        brand:id.brand.name,
                        agency:id.agency.name,
                    }
                })
            },
            influencersList(){
                return this.entriesInfluencers.map(id=>{
                    return{
                        id:id.id,
                        name: id.social_networks.instagram,
                        is_booking: id.is_booking
                    }
                })
            },
            currentUser:{
                get(){
                    return this.$store.state.currentUser.user
                }
            },
        },
        methods: {
            vacio(value){
                if(value==''||value==null||value==undefined){
                    return true
                }
            },
            close () {
                this.gris2 = false
                this.expense = {
                    sale_id:'',
                    percentage_over_sale:'',
                    concept:'',
                    influencer_id:'',
                    user_id:'',
                    payment_date:'',
                    deadline_date:''
                },
                this.$nextTick(() => {
                    this.$emit("closeCreateDialogExpense", false);
                })
            },
            save(){
                this.gris2 = true
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/expenses", this.expense).then(response=>{
                    this.close()
                    this.snackbar = {
                        message: 'Gasto creado con éxito!',
                        color: 'successful',
                        show: true
                    }
                }).catch(error => {
                    this.gris2 = false
                    this.snackbar = {
                        message: error.response.data.message,
                        color: 'error',
                        show: true
                    }
                })
            },
        },
}
</script>
<style lang="css" scoped>
.v-text-field >>> label{
    font-size: 14px!important;
}
</style>
<style>
.v-input__icon--prepend-inner i::before {
    font-size: 18px!important;
    color:#1976D2;
}
.v-text-field input, .v-label {
    font-size: 14px;
}
.v-picker__title.primary{
    display:none;
}
.space .theme--light.v-input input, .theme--light.v-input textarea {
    padding: 15px 20px;
}
</style>