<template>
    <v-card>
        <v-row v-if="sobrepasa!='no' && quotation.in_credit" class="ma-0 py-2" style="background:#FF5252; color:white;">
            <v-spacer/>
            <strong>La venta requiere autorizacion ya que sobrepasa los {{(sobrepasa*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}} MXN </strong>
            <v-spacer/>
        </v-row>
        <v-card-title class="py-0" >
            <v-icon style="background: #e7e8e9; padding: 10px!important; border-radius: 50%;" class="mr-4">mdi-file-document-outline</v-icon>
            <span v-if="!enable_expense">Nueva {{quole}}</span>
            <span v-if="enable_expense">Nuevo Gasto</span>
            <v-spacer></v-spacer>
            <!--v-col cols="12" sm="6" md="2" class="pb-0 mb-0">
                <v-radio-group v-model="type" class="my-0">
                    <v-radio label="Agencia" color="primary" value="agency"></v-radio>
                    <v-radio label="Marca" color="primary" value="brand"></v-radio>
                </v-radio-group>
            </v-col-->
            <v-col cols="12" sm="6" md="7" class="pb-0 pt-1">
                <v-autocomplete class="pb-0" :rules="[v => !!v || 'Campo requerido']" v-if="permissions('assignSales')" clearable v-model="quotation.user_id" :items="usersLists" label="Responsable Peach" item-text="full_name" item-value="id">
                    <template slot="no-data" class="pa-2">No existen usuarios relacionados.</template>                      
                </v-autocomplete>
                <v-autocomplete class="pb-0" v-else-if="quotation.user_id=currentUser.id" disabled v-model="quotation.user_id" :items="usersLists" label="Responsable Peach" item-text="full_name" item-value="id">                
                </v-autocomplete>
            </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 mb-0">
            <v-container v-if="!enable_expense">
                <v-row class="pt-5 pb-0">
                    <v-col class="pt-0" cols="12" sm="6" md="9">
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.agency_id" :items="agencyLists" :loading="isLoadingAgencies" :search-input.sync="searchAgencies" hide-no-data item-value="id" item-text="name" label="Agencia(s)" placeholder="Escribe para buscar">
                                    <template slot="no-data"><div class="px-4 py-1">No existen agencias relacionadas.</div></template>  
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-autocomplete :disabled="quotation.agency_id == null" :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.brand_id" :items="brandLists" :loading="isLoadingBrands" :search-input.sync="searchBrands" hide-no-data item-value="id" item-text="name" label="Marca(s)" placeholder="Escribe para buscar">
                                    <template slot="no-data"><div class="px-4 py-1">No existen marcas relacionadas.</div></template>  
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-col> 
                    <v-col class="pt-0" cols="12" sm="6" md="3">
                        <v-autocomplete clearable v-model="quotation.sale_type" :items="series" label="Tipo de Venta">
                            <template slot="no-data" class="pa-2">No existen tipos de venta relacionados.</template>                      
                        </v-autocomplete>
                    </v-col>
                </v-row>  

                <v-row class="mx-0 mb-0" v-if="quotation.agency_id!=null">
                    <v-autocomplete class="mr-4" :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.finance_contact_id" :items="agency_contacts" item-value="id" item-text="name" label="Contacto de Finanzas" placeholder="Escribe para buscar">
                        <template v-slot:item="{item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title style="font-size:14px;">
                                        <v-row class="ma-0 mt-2" style="color:grey;">
                                            <div>
                                                {{item.name + ' ' + item.last}}
                                                <br/>
                                                 <span style="font-size:11px; font-weight:600; color:grey;">
                                                    {{item.email}} | {{item.phone}}
                                                </span>
                                            </div>
                                            <v-spacer/>
                                            <v-chip class="my-1" x-small>{{item.job_position}}</v-chip>
                                        </v-row>
                                       
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template> 
                    </v-autocomplete>
                    <v-autocomplete class="ml-4" :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.campaign_contact_id" :items="agency_contacts" item-value="id" item-text="name" label="Contacto de Campaña" placeholder="Escribe para buscar">
                        <template v-slot:item="{item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-content>
                                    <v-list-item-title style="font-size:14px;">
                                        <v-row class="ma-0 mt-2" style="color:grey;">
                                            <div>
                                                {{item.name + ' ' + item.last}}
                                                <br/>
                                                 <span style="font-size:11px; font-weight:600; color:grey;">
                                                    {{item.email}} | {{item.phone}}
                                                </span>
                                            </div>
                                            <v-spacer/>
                                            <v-chip class="my-1" x-small>{{item.job_position}}</v-chip>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template> 
                    </v-autocomplete>
                </v-row>

                <v-row class="ma-0" v-if="quotation.finance_contact_id != null || quotation.campaign_contact_id != null">
                    <v-text-field class="py-0 mr-2" :disabled="quotation.finance_contact_id == null" v-model="quotation.email" label="Email Agencia"></v-text-field>
                    <v-text-field class="py-0 ml-2" :disabled="quotation.campaign_contact_id == null" v-model="whatsapp" label="WhatsApp Agencia"></v-text-field>
                    <v-switch inset class="pa-0 ma-0 ml-6 mt-1" v-model="whastapp_app" label="WhatsApp App"></v-switch>
                </v-row>


                <v-row style="background-color: #94949417;" class="px-8 ma-0 py-1 mb-0">
                    <v-col cols="12" sm ="4" md="4" class="pl-0">
                        <v-autocomplete :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.influencer_id" :items="influencerLists" :loading="isLoadingInfluencers" :search-input.sync="searchInfluencers" hide-no-data item-value="id" item-text="name" label="Influencer(s)" placeholder="Escribe para buscar">
                            <template slot="no-data"><div class="px-4 py-1">No existen influencers relacionadas.</div></template>  
                            <template v-slot:item="{item, attrs, on}">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-content>
                                        <v-list-item-title style="font-size:14px;">
                                            {{item.name}}
                                            <v-row class="ma-0 mt-2" style="color:grey;">
                                                <v-chip  v-if="item.is_booking" x-small>Booking</v-chip>
                                                <v-chip  v-else x-small>Management</v-chip>
                                            <v-spacer/>
                                                <span v-if="item.commission_percentage!=null" style="font-size:11px; font-weight:600;">Comisión:
                                                    <strong>{{item.commission_percentage}}%</strong>
                                                </span>
                                            </v-row>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template> 
                            
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm ="8" md="4">
                        <v-text-field v-model="quotation.influencer_amount" prefix="$" label="Comisión"></v-text-field><!-- :disabled="quotation.total==0" -->
                    </v-col>
                    <v-col cols="12" sm ="8" md="4">
                        <v-text-field v-model="quotation.campaign" label="Campaña"></v-text-field>
                    </v-col>

                    

                    <v-row class="mb-0">
                        <v-col ols="12" sm ="8" md="4" class="pt-1 pb-0 my-0">
                            <v-text-field v-model="quotation.service_date" label="Fecha de Servicio"></v-text-field>
                        </v-col>
                        <v-col ols="12" sm ="8" md="4" class="pt-1 pb-0 my-0">
                            <v-text-field v-model="quotation.service" :disabled="quotation.influencer_id==''" label="Servicio"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm ="8" md="4" class="pt-1 pb-0 my-0">
                            <v-text-field v-model="quotation.subtotal" :disabled="quotation.influencer_id==''" type="number" prefix="$" label="Precio (sin IVA)"></v-text-field>
                        </v-col>
                        <v-row class="ma-0">
                            <v-spacer/>
                            <v-col cols="12" sm ="8" md="4" class="py-0 my-0">
                            <v-switch class="my-0 pt-1 pb-0" v-model="quotation.in_credit" inset label="Venta a Crédito"></v-switch>
                            </v-col>
                            <v-col cols="12" sm ="8" md="4" class="py-0 my-0">
                                <v-switch class="my-0 pt-1 pb-0" v-model="dolares" inset label="Mostrar al cliente en dolares"></v-switch>
                            </v-col>
                            <v-col cols="12" sm ="8" md="4" class="py-0 my-0" v-if="dolares">
                                <v-text-field class="py-0 my-0" v-model="quotation.email_amount" :disabled="quotation.influencer_id==''" type="number" prefix="$" label="Total en dolares"></v-text-field>
                            </v-col>
                        </v-row>
                        
                    </v-row>
                </v-row>
                <v-row class="mt-0 px-6">
                    <v-col cols="12" sm="6" md="6" class="mt-0 pt-0">
                        <v-container fluid>
                            <v-textarea v-model="quotation.description" label="Descripcion"></v-textarea>
                        </v-container>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="mt-0 pt-0">
                        <v-row class="ma-0">
                            <v-text-field v-model="quotation.invoice" label="Factura"></v-text-field>
                            
                            <v-menu top offset-x class="hidden-md-and-down">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn style="font-size:11px;" text v-bind="attrs" v-on="on" :color="fileColor(quotation.peach_invoice_file)" class="mt-2">
                                        <v-icon class="mr-2" small v-if="!enable_expense">mdi-attachment</v-icon> Factura Marca
                                    </v-btn>
                                </template>
                                <vue-dropzone ref="myVueDropzone3" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess3" v-on:vdropzone-error="uploadError3" v-on:vdropzone-removed-file="fileRemoved3"/>
                            </v-menu>
                        </v-row>

                        <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" clearable required v-model="quotation.invoice_date" label="Fecha de Facturación" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotation.invoice_date" @input="datePicker = false"></v-date-picker>
                        </v-menu>

                        <!--v-menu v-model="datePicker2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.payment_promise_date" label="Fecha Promesa de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotation.payment_promise_date" @input="datePicker2 = false"></v-date-picker>
                        </v-menu-->

                        <v-menu v-model="datePicker2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" required v-model="quotation.deadline_date" label="Fecha Cierre" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="quotation.deadline_date" @input="datePicker2 = false"></v-date-picker>
                        </v-menu>

                    </v-col>
                </v-row>
            </v-container>
            <v-container class="mb-0 pb-0" v-else>
                <v-row v-for="(expense, index) in expenses" :key="index" class="py-6">

                    <v-col cols="12" sm="5" md="5">
                        <v-select class="mt-0 pt-0" v-model="expense.concept" :items="concepts" label="Concepto de Gasto"></v-select>
                        <v-autocomplete class="mt-0 pt-0" v-if="expense.concept == 'Producción'" :rules="[v => !!v || 'Campo requerido']" v-model="expense.user_id" :items="producersLists" label="Usuario" item-text="full_name" item-value="id">
                            <template slot="no-data" class="pa-2">No existen usuarios relacionados.</template>                      
                        </v-autocomplete>
                        <v-text-field  class="mt-0 pt-0" v-model="expense.amount" type="number" label="Monto (sin IVA)" prefix="$"></v-text-field>
                        <v-menu class="mt-0 pt-0" v-model="expense.datePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                            <template v-slot:activator="{ on }">
                                <v-text-field :rules="[v => !!v || 'Campo requerido']" required v-model="expense.payment_date" label="Fecha de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                            </template>
                            <v-date-picker color="primary" v-model="expense.payment_date" @input="expense.datePicker = false"></v-date-picker>
                        </v-menu>
                        
                    </v-col> 

                    <v-col cols="12" sm="5" md="5">
                        
                        <v-textarea class="space" background-color="amber lighten-4" v-model="expense.note" label="Nota"></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="2" md="2">
                        <v-spacer/>
                            <v-icon @click="remove(index)" v-show="index || ( !index && expenses.length > 1)" color="red">mdi-close</v-icon>
                            <v-icon @click="add(index)" :disabled="expense.concept==''&&expense.amount&&expense.payment_date==''" v-show="index == expenses.length-1" color="primary">mdi-plus</v-icon>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-divider v-show="index != expenses.length-1"></v-divider>
                    </v-col>
                </v-row>
                
            </v-container>
        </v-card-text>
        <v-row class="ma-0 pa-0 mt-0 pt-0">
            <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">
                <v-menu top offset-x class="hidden-md-and-down">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="font-size:12px;" text v-bind="attrs" v-on="on" :color="fileColor2(quotation.pdfs)">
                            <v-icon class="mr-2" v-if="!enable_expense">mdi-attachment</v-icon> Contrato*
                        </v-btn>
                    </template>
                    <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions2" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-error="uploadError" v-on:vdropzone-removed-file="fileRemoved"/>
                    <div class="pa-2" style="background:white;">
                        <v-chip close @click:close="removePdf(pdf)" small :href="'https://unopack.mx/files/' + pdf" target="_blank" v-for="(pdf, index) in quotation.pdfs" :key="index">{{pdf}}</v-chip>
                    </div>
                </v-menu>

                <v-menu top offset-x class="hidden-md-and-down">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn style="font-size:12px;" text v-bind="attrs" v-on="on" :color="fileColor(quotation.invoice_file)">
                            <v-icon class="mr-2" v-if="!enable_expense">mdi-attachment</v-icon> Factura Influencer
                        </v-btn>
                    </template>
                    <vue-dropzone ref="myVueDropzone2" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-success="uploadSuccess2" v-on:vdropzone-error="uploadError2" v-on:vdropzone-removed-file="fileRemoved2"/>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="mt-0 pt-0">
                <v-card-actions>
                    <v-spacer class="hidden-md-and-down"></v-spacer>
                    <v-btn color="grey darken-2" text @click="close" :disabled="gris2">
                        Cancelar
                    </v-btn>
                    <v-btn color="primary" v-if="!enable_expense" text @click="enable_expense=true" :disabled="gris || gris2">
                        Agregar Gasto
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn color="primary" v-else text @click="enable_expense=false" :disabled="gris || gris2">
                        <v-icon>mdi-chevron-left</v-icon>
                        Regresar
                    </v-btn>
                    <v-btn color="primary" class="elevation-0" @click="save" :disabled="gris || gris2" :loading="gris2">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show" style="text-align:center;">
            <strong>{{ snackbar.message }}</strong>
        </v-snackbar>
    </v-card>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import CreateContact from "../clients/contacts/create"
import CreateCompany from "../clients/companies/create"
import axios from "axios";
    export default {
        props:{
            message:Array
        },
        components: {
            vueDropzone: vue2Dropzone,
            'createContact':CreateContact,
            'createCompany':CreateCompany,
        },   
        data: () => ({
            whastapp_app:false,
            enable_expense: false,
            snackbar: {
                show: false,
                message: null,
                color: null
            },
            fileName3:'',
            fileName2:'',
            fileName:'',
            gris2: false,
            company:'',
            status:'',
            datePicker:false,
            datePicker2:false,
            dropzoneOptions: {
                url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/file",
                addRemoveLinks: true,
                maxFiles: 1
            },
            dropzoneOptions2: {
                url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/file",
                addRemoveLinks: true,
                maxFiles: 2
            },
            series:[{text:'Con Factura', value:'Serie A'}, {text:'Sin Factura', value:'Serie B'}],
            whatsapp:'',
            influencer_name:'',
            dolares:false,
            quotation:{
                campaign_contact_id:null,
                finance_contact_id:null,
                email:'',
                brand_id:null,
                agency_id:null,
                user_id:'',
                pdfs:[],
                description:'',
                sale_status:'quotation',
                sale_type:'',
                invoice:'',
                created_by_user_id:'',
                last_updated_by_user_id:'',
                influencer_id:'',
                service_date:'',
                service:'',
                subtotal:0,
                total:'',
                influencer_amount:null,
                campaign:'',
                payment_promise_date:'',
                deadline_date:'',
                expenses:[],
                email_amount:0,
                invoice_file:'',
                peach_invoice_file:'',
                due_date:'',
                needs_authorization:false,
                in_credit:true
            },
            expenses:[{
                percentage_over_sale:'',
                concept:'',
                influencer_id:'',
                user_id:'',
                payment_date:'',
                deadline_date:'',
                amount:'',
                datePicker:false,
                status:'activo'
            }],
            concepts:['Viaticos', 'Producción'], //, 'Prestamo Influencer'
            rules: {
                required: value => !!value || 'Campo requerido',
            },
            valid: true,
            agency_contacts:[],
            entriesAgencies:'',
            entriesBrands:'',
            entriesInfluencers:'',
            isLoadingAgencies: false,
            isLoadingBrands: false,
            isLoadingInfluencers: false,
            searchAgencies:'',
            searchBrands:'',
            searchInfluencers:'',
            credit_amount:0
        }),
        watch: {
            expenses:{
                handler(){
                    for(var i=0; i<this.expenses.length; i++){
                        this.expenses[i].deadline_date = this.quotation.deadline_date
                        if(this.expenses[i].concept == 'Viaticos'){
                            this.expenses[i].influencer_id = this.quotation.influencer_id
                        }
                    }
                }, deep:true
            },
            finance_contact:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        this.quotation.email = this.agency_contacts.filter(contact=>contact.id == this.finance_contact)[0].email
                    }
                },
                deep: true,
            },
            campaign_contact:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        this.whatsapp = this.agency_contacts.filter(contact=>contact.id == this.campaign_contact)[0].phone
                    }
                },
                deep: true,
            },
            agency:{
                handler: function (val, oldVal) {
                    if((val*1)!=(oldVal*1)){
                        //this.quotation.email = this.agencyLists.filter(agency=>agency.id == this.agency)[0].email
                        //this.whatsapp = this.agencyLists.filter(agency=>agency.id == this.agency)[0].phone
                        this.agency_contacts = this.entriesAgencies.filter(agency=>agency.id == this.agency)[0].contacts
                        this.credit_amount = this.entriesAgencies.filter(agency=>agency.id == this.agency)[0].credit_limit

                        const creditDays = this.entriesAgencies.filter(agency=>agency.id == this.agency)[0].credit_days;
                        console.log(creditDays)
                        if(creditDays!=undefined){

                            var date = new Date()
                            console.log(date)
                            date.setDate(date.getDate() + creditDays);
                            console.log(date)
                            this.quotation.payment_promise_date = new Date(date).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10);
                            this.quotation.due_date = new Date(date).toLocaleString("sv-SE", {timeZone: "America/Monterrey"}).toString().slice(0, 10);
                            console.log(this.quotation.payment_promise_date)
                            
                        }
                        
                        
                    }
                },
                deep: true,
            },
            influencer:{
                handler: function (val, oldVal) {
                    if(this.entriesInfluencers!=undefined&&this.entriesInfluencers.length>0){
                    this.influencer_name = this.entriesInfluencers.filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.social_networks.instagram)[0]
                    }
                    /*if((val*1)!=(oldVal*1)){
                        var commission_percentage = this.entriesInfluencers.filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.commission_percentage)[0]*1
                        this.quotation.influencer_amount = (((this.quotation.total/1.16)/100)*commission_percentage).toFixed(2)
                    }*/
                },
                deep: true,
            },
            /*total:{
                handler: function (val, oldVal) {
                    
                    console.log((val*1)!=(oldVal*1))
                    if((val*1)!=(oldVal*1)){
                        console.log('paso')
                        var commission_percentage = this.entriesInfluencers.filter(influencer=>influencer.id == this.influencer).map(influencer=>influencer.commission_percentage)[0]*1
                        if(commission_percentage!==0){
                            this.quotation.influencer_amount = (((this.quotation.total/1.16)/100)*commission_percentage).toFixed(2)
                        }
                    }
                    
                },
                deep: true,
            },*/
            searchInfluencers(val){
                if(val!=null){
                    var link = ''
                    if(this.currentUser.role == 'Talent Agent' || this.currentUser.role == 'Booking'){
                        link = '&filter[parent_id]=' + this.currentUser.id
                    }
                    //if (this.influencerLists.length > 0) return
                    if (this.isLoadingInfluencers) return
                    this.isLoadingInfluencers = true
                    axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/influencer/search?filter[social_networks]='+val+link)
                    .then(res => {
                        if(this.entriesInfluencers.length>0){
                            this.entriesInfluencers = this.entriesInfluencers.concat(res.data.data)
                        }else{
                            this.entriesInfluencers = res.data.data
                        }
                    }).finally(() => (this.isLoadingInfluencers = false))
                }   
            },
            searchAgencies(val){
                if (this.isLoadingAgencies) return
                this.isLoadingAgencies = true
                //var filter = this.$store.state.currentUser.client_filter
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/agencies?'+'filter[name]='+val)//+filter
                .then(res => {
                    //this.provisionalEntriesAgencies = JSON.parse(JSON.stringify(res.data.data))
                    if(this.entriesAgencies.length>0){
                        this.entriesAgencies = this.entriesAgencies.concat(res.data.data)
                    }else{
                        this.entriesAgencies = res.data.data
                    }
                }).finally(() => (this.isLoadingAgencies = false))
            },
            searchBrands(val){
                if (this.isLoadingBrand) return
                this.isLoadingBrand = true
                var filter = ''
                if(this.quotation.agency_id!=null){
                    filter = 'filter[agency_id]=' + this.quotation.agency_id + '&'
                }
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/brands?' + filter + "filter[name]=" + val)
                .then(res => {
                    if(this.entriesBrands.length>0){
                        this.entriesBrands = this.entriesBrands.concat(res.data.data)
                    }else{
                        this.entriesBrands = res.data.data
                    }
                }).finally(() => (this.isLoadingBrand = false))
            },
        },
        computed: {
            sobrepasa(){
                if(this.credit_amount<0.01 || this.credit_amount == null || this.credit_amount == '' || this.credit_amount == undefined){
                    return 'no'
                }
                else if(this.credit_amount <= this.quotation.subtotal*1.16){
                    return this.credit_amount
                }else{
                    return 'no'
                }
            },
            finance_contact(){
                return this.quotation.finance_contact_id
            },
            campaign_contact(){
                return this.quotation.campaign_contact_id
            },
            agency(){
                return this.quotation.agency_id
            },
            influencer(){
                return this.quotation.influencer_id
            },
            total(){
                return this.quotation.total
            },
            hayGastos(){
                var value = false
                for(var i=0; i<this.expenses.length; i++){
                    if(!this.vacio(this.expenses[i].percentage_over_sale)||
                    !this.vacio(this.expenses[i].amount)||
                    !this.vacio(this.expenses[i].concept)||
                    !this.vacio(this.expenses[i].influencer_id)||
                    !this.vacio(this.expenses[i].user_id)||
                    !this.vacio(this.expenses[i].payment_date)||
                    !this.vacio(this.expenses[i].deadline_date)
                    ){
                        value = true
                    }
                }
                return value
            },
            faltanDatosDeGatos(){
                var value = false
                for(var i=0; i<this.expenses.length; i++){
                    if(
                        (this.vacio(this.expenses[i].percentage_over_sale)&&this.vacio(this.expenses[i].amount))||
                         this.vacio(this.expenses[i].concept)||
                        (this.vacio(this.expenses[i].influencer_id)&&this.vacio(this.expenses[i].user_id))||
                        (this.vacio(this.expenses[i].payment_date)&&this.vacio(this.expenses[i].deadline_date))
                    ){
                        value = true
                    }           
                }
                return value
            },
            gris(){
                if(this.vacio(this.quotation.finance_contact_id)||this.vacio(this.quotation.campaign_contact_id)||this.vacio(this.quotation.influencer_id)||this.vacio(this.quotation.brand_id)||this.vacio(this.quotation.agency_id)||this.vacio(this.quotation.deadline_date)){//||this.quotation.pdfs.length<1){
                    return true
                }else{
                    if(this.hayGastos){
                        if(this.faltanDatosDeGatos){
                            return true
                        }else{
                            return false
                        }   
                    }else{
                        return false
                    }
                }
            },
            influencerLists(){
                if(this.entriesInfluencers!=''){
                    var perro = this.entriesInfluencers.map(id=>{
                        return{
                            id:id.id,
                            name: id.social_networks.instagram,
                            is_booking: id.is_booking,
                            commission_percentage:id.commission_percentage
                        }
                    })
                    return perro
                }
            },
            agencyLists(){
                if(this.entriesAgencies!=''){
                    return this.entriesAgencies.map(id => {
                        return{
                            id:id.id,
                            name:id.name,
                            phone:id.phone,
                            email:id.email
                        }
                    })
                }
            },
            brandLists(){
                if(this.entriesBrands!=''){
                    return this.entriesBrands.map(id => {
                        return{
                            id:id.id,
                            name:id.name
                        }
                    })
                }
            },
            usersLists(){
                return this.$store.state.user.salesman.map(id=>{return{
                    ...id,
                    full_name: id.name + ' ' + id.last
                }});
            }, 
            producersLists(){
                return this.$store.state.user.users.filter(user=>user.role == "Producción").map(id=>{return{
                    ...id,
                    full_name: id.name + ' ' + id.last
                }});
            },
            currentUser:{
                get(){
                    return this.$store.state.currentUser.user
                }
            },
            quole(){
                if(this.status=='vendido'){
                    return 'Venta'
                }else if(this.status=='quotation'){
                    return 'Cotización'
                } 
            } 
        },
        created(){
            this.company=this.message[0]
            this.status=this.message[1]
            if(this.company!=undefined){
                this.quotation.company_id=Number(this.company)
            }
        },
        methods: {
            fileColor(item){
                if(item!=''&&item!=undefined&&item!=null){
                    return 'success'
                }
            },
            fileColor2(item){
                if(item!=''&&item!=undefined&&item!=null){
                    return 'success'
                }else{
                    return 'warning'
                }
            },
            vacio(value){
                if(value==''||value==null||value==undefined){
                    return true
                }
            },
            isBooking(){
                return this.influencerLists.filter(influencer=>influencer.id == this.quotation.influencer_id).map(influencer=>influencer.is_booking)[0]
            },
            permissions(permission){
                if(this.currentUser.id==1){
                    return true
                }else if(this.currentUser.permissions!=undefined){
                    if(this.currentUser.permissions.includes(permission)){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },
            uploadSuccess2(file, response) {
                const perro = response.file
                console.log('File Successfully Uploaded with file name: ' + perro);
                this.fileName2 = file;
                this.quotation.invoice_file = perro;
            },
            uploadError2(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved2() {
                this.quotation.invoice_file = ''
            },
            uploadSuccess3(file, response) {
                const perro = response.file
                console.log('File Successfully Uploaded with file name: ' + perro);
                this.fileName3 = file;
                this.quotation.peach_invoice_file = perro;
            },
            uploadError3(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved3() {
                this.quotation.peach_invoice_file = ''
            },

            uploadSuccess(file, response) {
                const perro = response.file
                console.log('File Successfully Uploaded with file name: ' + perro);
                this.$refs.myVueDropzone.removeFile(file)
                this.quotation.pdfs.push(perro);
            },
            uploadError(file, message) {
                console.log('An Error Occurred');
            },
            fileRemoved() {
                
            },
            removePdf(pdf_to_remove){
                const respuesta = confirm("¿Estás segura de que deseas eliminar el archivo " + pdf_to_remove + "?");
                if (respuesta) {
                    this.quotation.pdfs = this.quotation.pdfs.filter(pdf=>pdf!=pdf_to_remove)
                }
            },
            close () {

                if(this.quotation.pdfs.length > 0){
                    const file = this.quotation.pdfs[this.quotation.pdfs.length - 1]
                    this.$refs.myVueDropzone.removeFile(file)
                }

                this.enable_expense = false
                this.expenses=[{
                    percentage_over_sale:'',
                    concept:'',
                    influencer_id:'',
                    user_id:'',
                    payment_date:'',
                    deadline_date:'',
                    amount:'',
                    datePicker:false,
                    status:'activo'
                }]
                this.gris2 = false
                this.quotation = {
                    campaign_contact_id:null,
                    finance_contact_id:null,
                    email:'',
                    brand_id:null,
                    agency_id:null,
                    user_id:'',
                    pdfs:[],
                    description:'',
                    sale_status:'quotation',
                    sale_type:'',
                    invoice:'',
                    created_by_user_id:'',
                    last_updated_by_user_id:'',
                    influencer_id:'',
                    service_date:'',
                    service:'',
                    subtotal:0,
                    total:'',
                    influencer_amount:null,
                    campaign:'',
                    payment_promise_date:'',
                    deadline_date:'',
                    expenses:[],
                    email_amount:0,
                    invoice_file:'',
                    peach_invoice_file:'',
                    due_date:'',
                    needs_authorization:false,
                    in_credit:true
                }
                if(this.fileName3 != ''){
                    this.$refs.myVueDropzone3.removeFile(this.fileName)
                    this.fileName = ''
                }
                if(this.fileName2 != ''){
                    this.$refs.myVueDropzone2.removeFile(this.fileName2)
                    this.fileName2 = ''   
                }
                this.$nextTick(() => {
                    this.$emit("closeCreateDialogQuotation", false);
                })
            },
            save(){
                if(this.quotation.in_credit){
                    if(this.sobrepasa!='no'){
                        this.quotation.needs_authorization = true
                    
                        if((this.credit_amount + this.quotation.subtotal*1.16)>1500000){
                            this.quotation.over_amount =  ((this.credit_amount+this.quotation.subtotal*1.16)) - 1500000
                        }else if((this.credit_amount + this.quotation.subtotal*1.16)>1000000){
                            this.quotation.over_amount =  ((this.credit_amount+this.quotation.subtotal*1.16)) - 1000000
                        }else if((this.credit_amount + this.quotation.subtotal*1.16)>500000){
                            this.quotation.over_amount =  ((this.credit_amount+this.quotation.subtotal*1.16)) - 500000
                        }

                    }
                }
                this.quotation.total = this.quotation.subtotal*1.16
                this.quotation.subtotal = this.quotation.subtotal*1
                if(this.hayGastos && !this.faltanDatosDeGatos){
                    this.quotation.expenses = this.expenses
                }else{
                    delete this.quotation.expenses
                }
                if(!this.dolares){
                    this.quotation.email_amount = (this.quotation.subtotal*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}) + ' MXN + IVA'
                }else{
                    this.quotation.email_amount = (this.quotation.email_amount*1).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}) + ' USD + IVA'
                }
                this.gris2 = true

                //this.quotation.deadline_date = this.quotation.payment_promise_date
                for(var i=0; i<this.expenses.length; i++){
                    this.expenses[i].deadline_date = this.quotation.deadline_date//payment_promise_date
                    //if(this.expenses[i].concept != 'Producción'){
                        this.expenses[i].influencer_id = this.quotation.influencer_id
                    //}
                }
                this.quotation.created_by_user_id = this.currentUser.id
                this.quotation.last_updated_by_user_id = this.currentUser.id
                if(this.quotation.user_id==''||this.quotation.user_id==undefined||this.quotation.user_id==null){
                    this.quotation.user_id = this.currentUser.id
                }
                this.quotation.sale_status = this.status
                this.$nextTick(() => {
                    console.log('UNOCRM v4.2.48')
                    axios
                    .post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales", this.quotation).then(response=>{
                        if(this.whatsapp!=undefined && this.whatsapp!=null && this.whatsapp!=''){
                            var text = '%E2%98%91+*CAMPA%C3%91A+CERRADA*%0D%0ACampa%C3%B1a+' + response.data + '%3A+%40' + encodeURIComponent(this.influencer_name) + '+x+' + encodeURIComponent(this.quotation.campaign) + '%0D%0AAcciones%3A+' + encodeURIComponent(this.quotation.service) + '%0D%0APresupuesto+' + encodeURIComponent(this.quotation.email_amount) + '%0D%0ACorreo+de+confirmaci%C3%B3n%3A+' + encodeURIComponent(this.quotation.email) + '&type=phone_number&app_absent=0'
                            if(this.whastapp_app){
                                window.open('https://api.whatsapp.com/send/?phone=' + this.whatsapp + '&text=' + text)//app
                            }else{
                                window.open('https://web.whatsapp.com/send/?phone=' + this.whatsapp + '&text=' + text)//web
                            }
                        }
                        this.close()
                        this.snackbar = {
                            message: 'Venta creada con éxito!',
                            color: 'successful',
                            show: true
                        }
                    })
                    .catch(error => {
                        this.snackbar = {
                            message: error.response.data.message,
                            color: 'error',
                            show: true
                        }
                        this.gris2 = false
                    })
                })
            },
            add(index) {
                this.expenses.push({
                    percentage_over_sale:'',
                    concept:'',
                    influencer_id:'',
                    user_id:'',
                    payment_date:'',
                    deadline_date:'',
                    amount:'',
                    datePicker:false,
                    status:'activo'
                });
            },
            remove(index) {
                this.expenses.splice(index, 1);
            },
        },
}
</script>
<style lang="css" scoped>
.v-text-field >>> label{
    font-size: 14px!important;
}
</style>
<style>
.v-input__icon--prepend-inner i::before {
    font-size: 18px!important;
    color:#1976D2;
}
.v-text-field input, .v-label {
    font-size: 14px;
}
.v-picker__title.primary{
    display:none;
}
.space .theme--light.v-input input, .theme--light.v-input textarea {
    padding: 15px 20px;
}
</style>